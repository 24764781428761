import React from 'react';
import './App.css';

import Nav from './components/Nav.js';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Landing from './components/Landing.js';
import Article from './components/Article.js';

export default () => {

  return (
    <Router>
      <>
        <Nav />
        <Switch>
          <Route exact path="/" component={Landing} />
          <Route path="/:article" component={Article} />
        </Switch>
      </>
    </Router>
  );
}