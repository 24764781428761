import React, { useEffect, useState, useRef } from "react";

import { Link } from "react-router-dom";
import Image from "./Image.js";

import Truncate from 'react-truncate';

export default ({ title, date, url, series, image, style, authors, description, index, preview}) => {
  const variantClass = index === 0 ? 'large' : index < 4 ? 'medium' : 'small'
  return (
    <li className={`ArticleList-item ArticleList-item--${variantClass}`} style={style}>
      <Link to={url}>
        <div className="ArticleList-item-meta  ff--basis label">{series} · {date}</div>
        <div className="ArticleList-item-label">{title}</div>
        <div className={`ArticleList-item-description ${variantClass === 'large' && "intro-p"}`}>{description}</div>
        <Image className="ArticleList-item-image" image={image} />
        {variantClass === "large" && <PreviewText text={preview} />}
      </Link>
    </li>
  )
}

const PreviewText = ({ text }) => {
  const ref = useRef()
  const [lines,setLines] = useState(3)
  useEffect(() => {
    window.addEventListener("resize",onResize);
    onResize()

    return () => {
      window.removeEventListener("resize",onResize);
    }
  },[ref.current])

  function onResize() {
    const rect = ref.current.getBoundingClientRect()
    const newLines = Math.floor(rect.height/20);
    setLines(newLines)
  }

  const isDesktop = window.innerWidth >= 600;
  const finalLines = isDesktop ? lines : 3;
  const innerStyle = isDesktop ? { position: 'absolute', width: '100%' } : {};

  return (
    <div className="ArticleList-item-preview font-size--label"
      ref={ref}
      style={{ position: 'relative' }}>
      {finalLines > 1 && <div style={innerStyle}>
      <Truncate lines={finalLines} ellipses={`...`}>
        {text}
      </Truncate>
      </div>}
    </div>
  )
}