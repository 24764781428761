import React, { useState, useEffect } from "react";
import ReactTooltip from "react-tooltip";
import { Link } from "react-router-dom";

import Loading from "./Loading.js";
import Image from "./Image.js";
import ArticleMeta from "./ArticleMeta.js";
import ArticleList from "./ArticleList.js";
import JsxParser from "react-jsx-parser";
import Share from "./Share.js";
import { DiscussionEmbed } from "disqus-react";

export default ({ match, setTitle, setTitleIsShowing }) => {
  const slug = match.params.article;
  const [data, updateData] = useState(null);

  useEffect(() => {
    fetchData();
  }, [slug]);

  async function fetchData() {
    updateData(null); //reset state to get loader
    fetch("https://api.americaoffbalance.org/article.json?slug=" + slug)
      .then((res) => res.json())
      .then(
        (response) => {
          updateData(response);
          window.scrollTo(0, 0);
        },
        (e) => {
          updateData("error");
          console.warn("fetch problem");
        }
      );
  }
  return data ? (
    <Article {...data} setTitleIsShowing={setTitleIsShowing} />
  ) : (
    <Loading />
  );
};

const Article = ({
  id,
  url,
  title,
  description,
  date,
  series,
  authors,
  body,
  image,
  style,
  setTitleIsShowing,
}) => {
  const splitBody = body.split(/\[footnote\](.*?)\[\/footnote\]/g);

  let footnotecount = 1;
  const footnotedBody = splitBody.reduce((r, d, i) => {
    r +=
      i % 2 === 0
        ? d
        : `<span className="footnote" data-tip='${d}' data-html="true">${footnotecount++}</span>`;
    return r;
  }, "");

  return (
    <main style={style}>
      <article className="Article">
        <div className="Article-inner">
          <div className="Article-title-wrap">
            <aside className="ff--basis label">{series}</aside>
            <h1 className="heading">{title}</h1>
            <div className="Article-meta label">
              <ArticleMeta date={date} authors={authors} />
            </div>
            {image && <Image image={image} />}
          </div>
          <div className="body">
            <JsxParser jsx={footnotedBody} />
            <Share
              url={`https://budgetmatters.americaoffbalance.org${url}`}
              text={description}
            />
          </div>
        </div>
        <ReactTooltip effect="solid" delayHide={200} delayShow={200} />
      </article>
      <div className="hide-on-print" style={{ margin: "1em auto 5em" }}>
        <Share
          url={`https://budgetmatters.americaoffbalance.org${url}`}
          text={description}
          orientation="HORIZONTAL"
        />
      </div>
      <section className="hide-on-print">
        <div className="Article-read-more text-align--center">
          <Link className="ff--basis label " to="/">
            Read More
          </Link>
        </div>
        <ArticleList not={[id]} limit={1} endpoint={"relatedPosts"} />
        <DiscussionEmbed
          shortname="america-off-balance"
          config={{
            url: window.location.href,
            identifier: "blog__" + id,
          }}
        />
      </section>
    </main>
  );
};
