import React from "react";
import { Transition } from "react-transition-group";

import Logo from "./AmericaOffBalanceIcon";
import HamburgerIcon from "./HamburgerIcon";
import HamburgerMenu from "./HamburgerMenu";

export default class Nav extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showHamburgerMenu: false,
    }
  }

  render() {
    const transitionStyles = {
      entered: { transform: "translateX(0)" },
      exiting: { transform: "translateX(-100%)" },
    };

    return (
      <nav className="Nav padding--nav">
        <div className="Nav-inner">
          <div className="Nav-hamburger-container">
            <button className="reset-button hamburger-btn" onClick={this.handleHamburgerClick}>
              <HamburgerIcon />
            </button>
            <a href="https://americaoffbalance.org" style={{ display: 'flex', alignItems: 'center ' }}>
              <Logo />
            </a>
          </div>

          <div className="Nav-links">
            <a className="Nav-link" href="https://americaoffbalance.org/tour">Tour the Budget</a>
            <a className="Nav-link" href="https://budgetmatters.americaoffbalance.org">Budget Matters</a>
            <a className="Nav-link" href="https://calculator.americaoffbalance.org">Fix the Budget</a>
          </div>
        </div>

        <Transition
          in={this.state.showHamburgerMenu}
          timeout={200}
          unmountOnExit
        >
          { state => {
            return (
              <HamburgerMenu
                style={{
                  ...transitionStyles[state]
                }}
                isMobile={this.props.isMobile}
                onDismissClick={this.handleHamburgerClick}
              />
            )
          }}
        </Transition>
      </nav>
    )
  }

  handleHamburgerClick = (e) => {
    e.preventDefault();
    this.setState({
      showHamburgerMenu: !this.state.showHamburgerMenu,
    })
  }
}
