import React from "react";
import Twitter from "./Twitter.js";
import LinkedIn from "./LinkedIn.js";
import Facebook from "./Facebook.js";
import Email from "./Email.js";
import Print from "./Print.js";

import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  EmailShareButton,
} from 'react-share';

export default({ url, text, orientation="VERTICAL" }) => {
  console.log(url)
  return (
    <div className={`Share Share--${orientation.toLowerCase()}`}>
      <TwitterShareButton url={url}>
        <Twitter />
      </TwitterShareButton>
      <LinkedinShareButton url={url}>
        <LinkedIn />
      </LinkedinShareButton>
      <FacebookShareButton url={url}>
        <Facebook />
      </FacebookShareButton>
      <EmailShareButton url={url}>
        <Email />
      </EmailShareButton>
      <button className="SocialMediaShareButton" onClick={()=> { window.print() }}><Print /></button>
      <div id="fb-root"></div>
    </div>
  )
}