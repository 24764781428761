import React, { useEffect, useState } from "react";

import Loading from './Loading.js';
import ArticleList from './ArticleList.js';

export default () => {
  useEffect(() => {
    fetchData()
  }, [])

  const [data,updateData] = useState(null)
  //const [typeFilter, setTypeFilter] = useState(null)
  const typeFilter = null
  const [sectionFilter, setSectionFilter] = useState(null)

  async function fetchData() {
    updateData(null)//reset state to get loader
    fetch('https://api.americaoffbalance.org/bloglanding.json')
      .then((res) => res.json())
      .then((response) => {
        updateData(response)
      }, (e) => {
        updateData("error")
        console.warn('fetch problem')
      })
  }

  return data ? (<main className="BlogLanding">
      <div className="BlogLanding-top">
        <div className="intro-p">{data.subtitle}</div>
        <h1 className="heading--huge uppercase">{data.title}</h1>
        <Filter items={data.topics} filter={sectionFilter} setFilter={setSectionFilter} everythingLabel={"All topics"} />
        {/* <Filter className="BlogLanding-filter--small" items={data.sections} filter={typeFilter} setFilter={setTypeFilter} everythingLabel={"All types"} /> */}
      </div>
      <ArticleList typeFilter={typeFilter} sectionFilter={sectionFilter} />
    </main>) : <Loading />
}

const Filter = ({ className, items, filter, setFilter, everythingLabel }) => {
  const toggleFilter = slug => {
    return () => {
      setFilter(filter === slug ? null : slug)
    }
  }
  return <div>
    <ul className={`BlogLanding-filter ${className} ff--basis label`}>
      <FilterButton id={null} title={everythingLabel} filter={filter} toggleFilter={toggleFilter} />
      {items.map((d) => <FilterButton key={d.id} {...d} filter={filter} toggleFilter={toggleFilter} />)}
    </ul>
  </div>
}

const FilterButton = ({ id, title, filter, toggleFilter }) => (
  <li key={id}>
    <button
      className={`BlogLanding-filter-button ${filter === id && 'BlogLanding-filter-button--active'}`}
      onClick={toggleFilter(id)}>
      {title}
    </button>
  </li>
)