import React, { useState, useEffect } from "react";
import { Trail } from 'react-spring';
import Loading from './Loading.js';
import ArticleListItem from './ArticleListItem.js';

export default ({ limit=100, not=null, typeFilter=null, sectionFilter=null, endpoint='posts' }) => {
  const [items, updateItems] = useState(null)

  useEffect(() => {
    fetchItems()
  }, [typeFilter,sectionFilter])

  async function fetchItems() {
    updateItems(null) //reset for loader
    fetch(`
      https://api.americaoffbalance.org/${endpoint}.json?
        limit=${limit}
        ${not ? ('&not=' + not.join(',')) : ''}
        ${typeFilter ? ('&typefilter=' + typeFilter) : ''}
        ${sectionFilter ? ('&sectionfilter=' + sectionFilter) : ''}
    `)
      .then((res) => res.json())
      .then((response) => {
        updateItems(response.data)
      }, (e) => {
        console.warn(e,'fetch problem')
      })
  }

  return items ?
    <ul className="ArticleList">
      <Trail
        config={{ friction: 20 }}
        items={items}
        keys={item => item.id}
        from={{ opacity: 0 }}
        to={{ opacity: 1 }}
        >
        {(item,index) => props =>
          <><ArticleListItem {...item} index={index} style={props} />{index === 0 && <div className="ArticleList-item-divider" />}</>
        }
      </Trail>
    </ul> : <Loading />
}